<template>
        <base-page>
        <titlu-pagina Titlu="Clienti" @on_add_clicked="show_dialog()" :AdaugaVisible='true' />
        <el-card style='margin:5px 0px 5px 0px' v-loading='loadingVisible'>
            <div slot="header" class="clearfix">
                <strong> Filtre </strong>
            </div>
            <div class="filtre">
                <el-form @submit.prevent.native='refresh_info()'>
                    <el-row :gutter="20">
                        <el-col :span='4'>
                            <el-form-item label='Nume' >
                                <el-input v-model='Filters.Nume' />
                            </el-form-item>
                        </el-col>
                        <el-col :span='4'>
                            <el-form-item label='PersoanaContact' >
                                <el-input v-model='Filters.PersoanaContact' />
                            </el-form-item>
                        </el-col>                        
                        <el-col :md='4' >
                            <el-button type='primary' native-type='submit' style='margin-top:48px'> Aplica </el-button>
                        </el-col>
                        <el-col :md='12'>
                            <p style='margin-top: 48px; text-align: right'>
                                Nr rezultate: {{PaginationInfo.RowCount}}
                            </p>
                        </el-col>
                    </el-row>
                </el-form>
                </div>
        </el-card>

        <el-table :data="Results" @sort-change='sort_table'  :default-sort = "{prop: 'DataExpirare', order: 'ascending'}">
            <el-table-column prop='Nume' label='Nume' sortable min-width='250'/>
            <el-table-column prop='TipFirma' label='Tip firma' sortable/>
            <el-table-column prop='Cui' label='Cui'/>
            <el-table-column prop='RegCom' label='Reg Com'/>
            <el-table-column prop='PersoanaContact' label='Persoana de contact'/>
            <el-table-column label='Data de expirare' prop='DataExpirare' sortable>
                <template slot-scope='scope'>
                    <el-tag :type="get_tagtype_pentru_data(scope.row.DataExpirare)">{{ scope.row.DataExpirare | momentformat('dd.MM.YYYY') }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label='Adresa' prop='Adresa'/>
            <el-table-column fixed="right" label="Actiuni" width="200px" >
                <template slot-scope="scope" >
                    <el-tooltip content="Modificare">
                        <el-button type="primary" icon="el-icon-edit"  circle @click="show_dialog(scope.row.Id)" />
                    </el-tooltip>

                    <el-tooltip content="Sterge" >
                        <el-button type="danger" icon="el-icon-delete" circle @click="delete_item(scope.row)" />
                    </el-tooltip>

                    <el-tooltip content="Trimite notificarea acum" >
                        <el-button type="warning" icon="el-icon-message" circle @click="trimite_notificare(scope.row)" />
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
        <Clienti-dialog ref='dlg' @save="refresh_info()" />
    </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Clienti_dialog from '@/pages/clienti/Clienti_dialog.vue';
import TitluPagina from '@/widgets/TitluPagina';
import moment from 'moment';

export default {
    name: "clienti",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'Clienti-dialog': Clienti_dialog,
        'titlu-pagina': TitluPagina
    },
    data () {
        return {
            Results: [],
            base_url: '',
            Info: {},
            Filters: { Nume: '' , PersoanaContact: '' },
            OrderBy: { Nume: '', DataExpirare: 'ASC', TipFirma: '' },
            PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
        }
    },
    methods: {

        get_tagtype_pentru_data(data){
            var ret = 'success';
            if( this.urmeaza_sa_expire(data) ) ret = 'warning';
            if( this.a_expirat(data) ) ret = 'danger';
            return ret;
        },

        urmeaza_sa_expire(data){
            var   now = moment();
            var _data = moment( data );
            var diff  = _data.diff(now,'days');
            if( diff <= 30 ) return true;
        },

        a_expirat(data){
            var   now = moment();
            var _data = moment( data );
            var diff  = _data.diff(now,'days');
            if( diff <= 0 ) return true;
        },

        async trimite_notificare(row){
            var response = await this.$confirm('Esti sigur ca doresti sa ii trimiti acum notificarea?', 'Warning', {
                confirmButtonText: 'DA',
                cancelButtonText: 'Renunt',
                type: 'warning'
            });
            if( response == 'confirm' ){
                await this.post("clienti/trimite_notificare", { id: row.Id } );
                this.$message({
                    message: 'Mesajul a fost trimis cu succes!',
                    type: 'success'
                });
            }
        },
        
        sort_table(prop, order){
            this.OrderBy = { Nume: '', DataExpirare: '', TipFirma: '' };
            if( prop.order == null ) 
                this.OrderBy[prop.prop] = '';
            else
                this.OrderBy[prop.prop] = prop.order == 'ascending' ? 'ASC' : 'DESC';

            this.refresh_info();
        },

        async get_info(){
            var response        = await this.post("clienti/get_info" );
            this.refresh_info();
        },

        async refresh_info(){
            var response        = await this.post("clienti/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
            this.Results        = response.Results;
            this.PaginationInfo = response.PaginationInfo;
            //
            this.select_menu_item('clienti');
        },
        reset(){
            this.Filters = { Nume: '' , PersoanaContact: '' };
            this.refresh_info();
        },
        async delete_item( item ){
            var confirm =  await this.$confirm(`Sunteti sigur ?`, 'Warning');
            if( confirm )
            {
                await this.post("clienti/delete_item", { id: item.Id } );
                this.refresh_info();
            }
        },

        show_dialog(id){
            this.$refs.dlg.show_me(id);
        }
    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.get_info();
    }
};
</script>

<style lang="less" scoped>

    .top50{
        margin-top: 20px;
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
    }

</style>
