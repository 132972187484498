<template>
        <el-dialog title="Clienti" :visible.sync="showPopup" class="my-dialog-class" >
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                
                <el-col :md='8'>
                    <el-form-item label='Nume' >
                        <el-input  class='full-width' v-model='selectedObject.Nume' />
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Cui' >
                        <el-input  class='full-width' v-model='selectedObject.Cui' />
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='RegCom' >
                        <el-input  class='full-width' v-model='selectedObject.RegCom' />
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='PersoanaContact' >
                        <el-input  class='full-width' v-model='selectedObject.PersoanaContact' />
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Email' >
                        <el-input  class='full-width' v-model='selectedObject.Email' />
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Telefon' >
                        <el-input  class='full-width' v-model='selectedObject.Telefon' />
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='DataExpirare' >
                        <el-date-picker  class='full-width' v-model='selectedObject.DataExpirare' type='date' value-format='yyyy-MM-dd' format='dd.MM.yyyy' />
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Adresa' >
                        <el-input  class='full-width' v-model='selectedObject.Adresa' />
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Tip firma' >
                         <el-select class='full-width' v-model="selectedObject.TipFirma" placeholder="Select">
                            <el-option label="RoLegal" value="RoLegal"/>
                            <el-option label="Servicii" value="Servicii"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :md='24'>
                    <el-form-item label='Observatii' >
                        <el-input  class='full-width' v-model='selectedObject.Observatii' />
                    </el-form-item>
                </el-col>            
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup=false"     > Renunta  </el-button>
            <el-button type="primary" @click="save" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';

    export default {
        name: "Clienti_dialog",
        extends: BasePage,
        data () {
            return {
                baseUrl :'',
                showPopup: false,
                mode: 'add',
                selectedObject: {Nume: '' , Cui: '' , RegCom: '' , PersoanaContact: '' , Email: '' , Telefon: '' , DataExpirare: '' , Adresa: '' , Observatii: '', TipFirma:'RoLegal'},
                Info:{},
                rules: {
                    //   Nume: [ { required: true, message: "Campul este obligatoriu" } ]
                    Nume: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Cui: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    RegCom: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    PersoanaContact: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Email: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Telefon: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    DataExpirare: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Adresa: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Observatii: [ { required: true, message: 'Campul este obligatoriu' } ], 
                }
            }
        },
        methods: {
            show_me: async function( id ) {
                this.showPopup        = true;
                if( id == null )
                {
                    this.mode = "add";
                }
                else
                {
                    this.mode             = "edit";
                    var            result = await this.post("clienti/get_info_item_dialog", { id: id } );
                    this.selectedObject   = result.Item;
                }
            },
            async get_info(){
                var response = await this.post("clienti/get_info_for_dialog" );
                            },
                        save: async function() {
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        await this.post("clienti/save", { mode: this.mode, object: this.selectedObject } );
                        this.showPopup = false;
                        this.$emit("save");
                    }
                });
            }
        },
        mounted: function() {
            this.baseUrl = settings.BASE_URL;
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

</style>